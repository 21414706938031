import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";

export default function useLobbyList() {
  const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
  // Register module
  if (!store.hasModule(MODULE_APP_STORE_NAME))
    store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
  const editTemplate = computed(() => {
    return store.state["app-whitelabel-templates"].editTemplate;
  });

  // Use toast
  const { t } = useI18nUtils();
  const toast = useToast()
  const refLobbyListTable = ref(null)
  const loading = ref(false)

  // Table Handlers
  const tableColumns = [

    { key: 'title', sortable: false },
    { key: 'actions' },
    { key: 'background_color', sortable: false },
  ]
  const perPage = ref(10)
  const totalLobby = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const recordDeleted = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refLobbyListTable.value ? refLobbyListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalLobby.value,
    }
  })
  const status = ref([
    {
      label:t('labels.all'),value:3
    },
    {
      label:t('labels.active'),value:1
    }
    ,{
      label:t('labels.disable'),value:0
    }
  ]);
  const statusSelected = ref({
    label:t('labels.all'),value:3
  });

  const refetchData = () => {
    refLobbyListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, recordDeleted,statusSelected], () => {
    refetchData()
  })



  const selectLobby = async (id) => {
    let lobbies = refLobbyListTable.value.localItems
    let selected = null

    headers = lobbies.map(item => {
      if (item._id === id) {
        item.selected = true
        selected = item
      } else {
        item.selected = false
      }
      return item
    })


    const updatedTemplate = {
      ...editTemplate.value,
      templateData: {
        ...editTemplate.value.templateData,
        lobby: selected
      }
    };

    store.commit('app-whitelabel-templates/SET_EDIT_TEMPLATE', updatedTemplate);

    await store.dispatch(
        "app-whitelabel-templates/updateTemplate",
        {
          template: updatedTemplate,
          toast,
          path: "templateData.lobby"
        }
    )

    refLobbyListTable.value.localItems = lobbies
    refLobbyListTable.value.refresh()
  }


  const fetchLobby = (ctx, callback) => {
    loading.value = true
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
      templateId: editTemplate.value._id,
    }
    if (statusSelected.value.value===1) params.status=true;
    if (statusSelected.value.value===0) params.status=false;

    store.dispatch('app-lobby/fetchLobbys', params)
      .then(response => {
        const { lobbies, total } = response.data

        for (const i in lobbies) {
          lobbies[i].selected = lobbies[i]._id === id;
        }

        callback(lobbies)
        totalLobby.value = total
        loading.value = false
      })
      .catch(() => {
        loading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Lobby list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchLobby,
    tableColumns,
    perPage,
    currentPage,
    totalLobby,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLobbyListTable,
    refetchData,
    selectLobby,
    loading,
    recordDeleted,
    status,
    statusSelected
  }
}
